/* You can add global styles to this file, and also import other style files */
@import "~bootstrap/dist/css/bootstrap.css";
@import './assets/css/custom.scss';
@import './assets/css/_banner.scss';
/* html, body { height: 100%; } */
/* body { margin: 0; font-family: Roboto, "Helvetica Neue", sans-serif; } */

.form-control{
  color:#6c757d;
}
.form-control:focus{
  color:#6c757d;
}


