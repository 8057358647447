.pac-container:after {
  /* Disclaimer: not needed to show 'powered by Google' if also a Google Map is shown */

  background-image: none !important;
  height: 0px;
}
@font-face {
    font-family: 'proxima_novablack';
    src: url('../fonts/proxima_nova_black-webfont.woff2') format('woff2'),
         url('../fonts/proxima_nova_black-webfont.woff') format('woff');
    font-weight: normal;
    font-style: normal;
}
@font-face {
    font-family: 'proxima_novabold';
    src: url('../fonts/proxima_nova_bold-webfont.woff2') format('woff2'),
         url('../fonts/proxima_nova_bold-webfont.woff') format('woff');
    font-weight: normal;
    font-style: normal;
}
@font-face {
    font-family: 'proxima_novaextrabold';
    src: url('../fonts/proxima_nova_extrabold-webfont.woff2') format('woff2'),
         url('../fonts/proxima_nova_extrabold-webfont.woff') format('woff');
    font-weight: normal;
    font-style: normal;
}
@font-face {
    font-family: 'proxima_novasemibold';
    src: url('../fonts/proxima_nova_semibold-webfont.woff2') format('woff2'),
         url('../fonts/proxima_nova_semibold-webfont.woff') format('woff');
    font-weight: normal;
    font-style: normal;
}
@font-face {
    font-family: 'proxima_novathin';
    src: url('../fonts/proxima_nova_thin-webfont.woff2') format('woff2'),
         url('../fonts/proxima_nova_thin-webfont.woff') format('woff');
    font-weight: normal;
    font-style: normal;
}
@font-face {
    font-family: 'proxima_novaregular';
    src: url('../fonts/proxima_nova_font-webfont.woff2') format('woff2'),
         url('../fonts/proxima_nova_font-webfont.woff') format('woff');
    font-weight: normal;
    font-style: normal;

}

body{font-family: 'proxima_novaregular' !important;line-height: normal !important;}
img{max-width: 100%;}
h1,h2,h3,h4,h5,h6{font-family: 'proxima_novabold' !important;line-height: normal !important;}
.btn-theme{background-color:#EF1C25;color: #fff;border-radius: 10px;padding: 10px 20px;font-size: 20px;font-family: 'proxima_novasemibold';}
.btn-theme:hover{background-color:#151E2D;color: #fff;}
.btn-theme-reverse{background-color:#151E2D;color: #fff;border-radius: 10px;padding: 12px 20px;font-size: 20px;font-family: 'proxima_novasemibold';}
.btn-theme-reverse:hover{background-color:#EF1C25;color: #fff;}
.btn-outline{color: #343A3F;border: 1px solid #343A3F !important;font-size: 20px;font-family: 'proxima_novasemibold';}
.btn-outline:hover{border-color: #343A3F;opacity: 0.8;}
.btn-sm{font-size: 16px;}
.form-group{margin-bottom:25px;}
.form-check-label{font-size: 18px;}
.form-check-input{width: 1.2em;height: 1.2em;margin-right: 10px;border: 1px solid #404040;min-width: 1.2em;}
.form-check-input:checked[type="checkbox"]{background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 20 20'%3e%3cpath fill='none' stroke='%23404040' stroke-linecap='round' stroke-linejoin='round' stroke-width='3' d='m6 10 3 3 6-6'/%3e%3c/svg%3e");background-color: transparent;border-color:#404040;}
.form-check{display: flex;}
.text-dacoration-none{text-decoration: none;}
select option{font-family: 'proxima_novaregular';}

.require{color: #EF1C25;}
.alert{background: none;border: none;padding: 0;}
.alert.alert-danger{color: red;}
.alert.alert-sucucess{color: green;}
.btn-theme.btn:disabled, .btn-theme.btn.disabled, fieldset:disabled .btn.btn-theme{color: #fff;background-color: #EF1C25;border-color: transparent;}

.form-floating > .form-control, .form-floating > .form-control-plaintext, .form-floating > .form-select{height: calc(3.2rem + 2px);}


.TableFilterContent .mat-mdc-paginator-range-actions{margin-left: auto;}
.AdminProperty .mat-mdc-table{box-shadow:none;}
.AdminProperty .mat-mdc-table .mdc-data-table__content{border: 1px solid #FEE;}
.AdminProperty .mat-mdc-table .mdc-data-table__content td:first-child{border-left-width: 2px;}
.AdminProperty .mdc-data-table__header-row th{background-color: #FFEEEE;color: #EF1C25;}
.AdminProperty .mdc-data-table__header-row th:first-child{border-top-left-radius: 20px;}
.AdminProperty .mdc-data-table__header-row th:last-child{border-top-right-radius: 20px;}
.AdminProperty .mdc-data-table__cell{padding: 5px 10px 5px 16px;color: #404040;font-size: 16px;}
// .cdk-overlay-pane{background-color: #fff;box-shadow: 0 2px 5px 0 #ddd;}
.TableFilterContent .mat-mdc-paginator-range-label{color: #888;}
.TableFilterContent .mat-mdc-paginator-page-size-label{color: #888;}
.TableFilterContent .mdc-notched-outline{color: #c8c8c8;}
.TableFilterContent .mat-mdc-select-value{padding: 5px 0;}
.TableFilterContent .mat-mdc-paginator-page-size{margin-right: auto;padding-bottom: 5px;}

.userImgBtn img{height: 42px;width: 42px;object-fit: cover;border-radius: 50%;margin-top: -1px;}
.btn-theme-reverse:disabled{background-color: #151E2D !important;color: #fff !important;}

.alert{margin-top:6px;}
.mat-mdc-paginator-range-label{margin: 0 15px 0 15px !important;white-space: nowrap;}
.admin-side-bar{padding-right:30px;padding-left:30px;max-width: 1920px; margin:0px auto;}

.mat-mdc-slide-toggle .mdc-switch__handle::before, .mat-mdc-slide-toggle .mdc-switch__handle::after {background-color: #EF1C25 !important;}
.mat-mdc-slide-toggle .mdc-switch:enabled .mdc-switch__track::after {background-color:#FFCFD1 !important;}

::-webkit-input-placeholder {color:#404040; }
:-ms-input-placeholder { color:#404040;}
::placeholder {color:#404040;}

.navbar-toggler{    background-color: #EF1C25;    padding: 2px 8px 5px;}
.navbar-toggler-icon{    width: 1.2em;    height: 1.2em;}


.navbar-toggler.collapsed .navbar-toggler-icon{background-image:url(../images/close-white.svg);background-size: 14px;}

.sidebarContent .mat-mdc-tab.mdc-tab{ font-size: 20px; font-weight: 600;}
.sidebarContent .mat-mdc-tab.mdc-tab.mdc-tab--active{color: #EF1C25;}

.editProfile .admin-side-bar{padding:0;}


.floorPlanSection .ng-image-slider .ng-image-slider-container .main .main-inner .img-div{box-shadow: none;}
.floorPlanSection .ng-image-slider .ng-image-slider-container .main .main-inner .img-div img{height: 140px !important;width: 200px !important;box-shadow: inset 0 0 1px #0000003d, 0 0 2px #0000001f;border-radius:10px;padding: 3px;min-width: 150px;object-fit: cover;}
.ng-image-slider .ng-image-slider-container .main .next, .ng-image-slider .ng-image-slider-container .main .prev{text-decoration: none;}

.headerCustom{background-image: linear-gradient(rgba(0,0,0,0.6), rgba(0,0,0, 0)); z-index: 99;}
.herobanner-home{position: relative;}
.herobanner-home::after{content: '';position: absolute;background-color: #000;opacity: 0.3;top: 0;left: 0;right: 0;bottom: 0;}
.herobanner-home .container{z-index: 9;}
.propertyAddSection .cardAddProperty, .ProfilelefMenu, .profileSection .profileRightContent{position: relative;}
.editProfile .container{padding: 0;}
.profileSection .profileRightSection{position: relative;}

app-sidebar-login[style="display: none;"] .sidebarLoginContent{left:auto;right: -100%;transition: 0.5s;}
app-sidebar-login[style=""] .sidebarLoginContent{left: 0;}





@media (max-width:767px){
    .form-check-label{font-size: 16px;}
    .btn-theme{padding: 10px 20px;}
    .mat-mdc-icon-button{width: 40px!important;height: 40px!important;}
    .admin-side-bar{padding-right: 15px;padding-left: 15px;}
}
@media (max-width:574px){
    .mat-mdc-icon-button{width:35px!important;}
    .btn-theme{padding:8px 18px;font-size:18px;}
    .btnSearch{
        .btn-theme{width:auto!important;margin:0 10px;
            svg{width:15px;}

        }
    }
    .form-group .form-control, .form-select{
        font-size: 14px;
    }
    .form-group{margin-bottom:15px;}

    .mat-mdc-paginator-range-label{padding-top: 10px;}
}


.cdk-overlay-pane .success {
  color: #0f5132!important;
  background-color: #d1e7dd!important;
  border-color: #badbcc!important;
}
.cdk-overlay-pane .error {
  color: #842029!important;
  background-color: #f8d7da!important;
  border-color: #f5c2c7!important;
}


.descriptionBid .form-control:focus ~ label, .descriptionBid .form-control:not(:placeholder-shown) ~ label{width: 114%!important;}

.ng-image-fullscreen-view .lightbox-wrapper .lightbox-div .next, .ng-image-fullscreen-view .lightbox-wrapper .lightbox-div .prev{
    background-color: rgba(0, 0, 0, 0.5019607843) !important;
    text-decoration: none;color:#fff !important; width: 45px!important;
    height: 45px!important;line-height: 40px!important;
}

.pac-item, .pac-item-query{font-size:16px;font-family: 'proxima_novaregular' !important;}
.pac-item{padding:5px;}
.pac-icon.pac-icon-marker{background-size:38px;width:20px;height:20px;background-position:21px 132px!important;}
.pac-logo:after{display: none;}
.pac-item:hover {background-color: #eee;}

.aminityIcons.d-flex{align-items: end;}



.countdownCUstom{
    background-color: #FFE4E5;
    text-align: center;
    justify-content: center;
    color:#EF1C25;

    div{margin-right: 10px;display: flex;align-items: center;padding-top:8px;padding-bottom:8px;}
    h2{font-size: 16px!important;font-weight: 100;}
    h3{font-size: 24px;line-height: 10px;}
}